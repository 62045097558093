.badge {
    display: inline-flex;
    padding: 0 8px;
    font-size: 13px;
    line-height: 22px;
    font-weight: 500;
    white-space: nowrap;
    vertical-align: middle;
    border-radius: 99999rem;
}

.success {
    background-color: rgba(38, 176, 42, .15);
    color: #26B02A;
}

.black {
    background-color: rgba(145, 145, 145, .15);
    color: rgb(145, 145, 145);
}

.danger {
    background-color: rgba(209, 85, 84, .15);
    color: #D15554;
}

.small {
    font-size: 12px;
    line-height: 18px;
    padding: 0 6px;
}

.warning {
    background-color: rgba(255, 193, 7, .15);
    color: #FFB149;
}