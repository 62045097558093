.table {
    width: 100%;
    border-collapse: collapse;

    --cross-table-radius: 10px;
    --cross-table-border-color: rgba(34, 34, 34, 0.1);
    table-layout: fixed;
}
.table thead th {
    padding: 16px 20px;
    min-width: 200px;
    font-weight: 400;
    color: #7C7C7C;
    text-align: center;
}

.table thead th:first-of-type {
    width: 290px;
    text-align: left;
}

.table tbody td {
    font-size: 14px;
    line-height: 1.4;
    padding: 16px 20px;
    text-align: center;
}

.table tbody td:first-child {
    font-weight: 700;
    text-align: left;
}

.table th:not(:first-of-type) {
    box-shadow: 0 0 0 1px var(--cross-table-border-color);
}

.table td {
    box-shadow: 0 0 0 1px var(--cross-table-border-color);
}

.table th:nth-child(2) {
  border-radius: var(--cross-table-radius) 0 0 0;
}

.table th:last-of-type {
    border-radius: 0 var(--cross-table-radius) 0 0;
}

.table th,
.table td {
    padding: 8px;
    background-color: #fff;
}

.table tbody tr:first-child td:first-of-type {
    border-radius: var(--cross-table-radius) 0 0 0;
}

.table tr:last-of-type td:first-of-type {
    border-radius: 0 0 0 var(--cross-table-radius);
}

.table tr:last-of-type td:last-of-type {
    border-radius: 0 0 var(--cross-table-radius) 0;
}

.table th:first-of-type {
    background-color: transparent;
}

.scrollable {
    overflow-x: auto;
    overflow-y: hidden;

    margin-bottom: -1px;
    padding: 1px 1px 2px;
    max-width: calc(100vw - var(--fullscreen-dialog-padding-x) * 2);
}

.scrollable .table {
   min-width: 760px;
}

.badge {
    vertical-align: middle;
    margin-left: 4px;
}

.title {
    font-weight: 700;
    font-size: 19px;
    color: #222;
}

.table tbody .divider {
    background-color: #E9E9E9;
    padding-top: 8px;
    padding-bottom: 8px;
    color: rgb(82, 82, 82, .6);
    font-size: 10px;
    font-weight: 400!important;
    letter-spacing: 0.5px;
    line-height: 1;
    box-shadow: none;
    text-transform: uppercase;
    box-shadow: 1px 0 0 0 var(--cross-table-border-color), -1px 0 0 0 var(--cross-table-border-color);
}