.searchbox {
    height: 3rem;
    background-color: #FFF;
    border-radius: 999px;
    box-shadow: var(--shadow-md);
    transition: box-shadow 0.2s ease-in-out;
}

.searchbox:focus-within {
    box-shadow: 0 0 0 4px rgba(13, 110, 253, .25), 0 0 0 1px #86b7fe;
}

.searchbox input {
    background-color: transparent;
    border: 0;
}

.searchbox input::placeholder {
    color: rgba(32, 32, 32, 0.3)
}

.searchbox input:focus {
    outline: none;
}
