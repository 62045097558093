.row {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: .5rem;
    text-align: center;
}

@media (min-width: 768px) {
    .row {
        flex-direction: row;
        justify-content: space-between;
    }
}