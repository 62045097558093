@font-face {
    font-family: "Pangea";
    src: url('https://banktrack.com/fonts/Pangea-ItalicWeb.woff2') format('woff2');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}
  
@font-face {
    font-family: "Pangea";
    src: url('https://banktrack.com/fonts/Pangea-BoldWeb.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
  
@font-face {
    font-family: "Pangea";
    src: url('https://banktrack.com/fonts/Pangea-RegularWeb.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
  
@font-face {
    font-family: "Pangea";
    src: url('https://banktrack.com/fonts/Pangea-SemiBoldWeb.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
  
@font-face {
    font-family: "Pangea";
    src: url('https://banktrack.com/fonts/Pangea-SemiBoldItalicWeb.woff2') format('woff2');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}
  
@font-face {
    font-family: "Pangea";
    src: url('https://banktrack.com/fonts/Pangea-BoldItalicWeb.woff2') format('woff2');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}
  
:root {
    --z-index-bubble: 100;
    --z-index-dropdown: 500;
    --z-index-sticky: 1000;
    --z-index-fixed: 1050;
    --z-index-full-modal: 1060;
    --z-index-modal-backdrop: 1100;
    --z-index-offcanvas: 1200;
    /* --z-index-full-modal: 1250; */
    --z-index-modal: 1300;
    --z-index-popover: 1400;
    --z-index-tooltip: 1500;

    --f-family-primary: "DM Sans", sans-serif;
    --f-family-secondary: "Pangea", sans-serif;
    --f-family-mono: "DM Mono", monospace;

    --shadow-sm: 0 1px 3px rgba(0 0 0 / 15%);
    --shadow-md: 0 2px 4px rgba(0 0 0 / 15%);
    --shadow-lg: 0 6px 14px rgb(0 0 0 / 15%);
}

.font-mono {
    font-family: var(--f-family-mono);
}

.font-sans {
    font-family: var(--f-family-primary);
}

a {
    text-decoration: underline;
    color: inherit;
}

.font-family-titles {
    font-family: "Pangea", sans-serif;
}

a:hover {
    color: inherit;
}

body {
    margin: 0;
    font-family: var(--f-family-primary);
    background-color: #FAF9F6;
}

h1.new, h2.new, h3.new, h4.new, h5.new, h6.new {
    font-family: "Pangea", sans-serif;
    font-weight: 700;
}

input {
    border-radius: 10px;
    border: 2px solid #DDD;
}

/*
 *
 * GENERAL CLASSES
 *
 */
.small {
    font-size: 0.875rem;
}

.text-very-small {
    font-size: 0.6875rem !important;
}

.text-small {
    font-size: 0.8rem !important;
}

.text-big {
    font-size: 1.2rem !important;
}

.text-decoration-none {
    text-decoration: none !important;
}

.hover-opacity {
    opacity: 0.75;
}

.hover-opacity:hover {
    opacity: 1;
}

.cursor-default {
    cursor: default !important;
}

.cursor-pointer {
    cursor: pointer !important;
}

.cursor-help {
    cursor: help;
}

.shadowed-box {
    box-shadow: var(--shadow-lg);
}

.shadowed-icon {
    box-shadow: var(--shadow-lg);
    border-radius: 999px;
}

.white-box {
    background-color: #FFFFFF;
    border-radius: 20px;
    box-shadow: var(--shadow-lg);
    padding: 5rem;
}

/*
 *
 * BUTTONS
 *
 */

.btn {
    height: 2.5rem;
    border-radius: 999px !important;
    border: none !important;
    padding: 0 1rem !important;
    opacity: 0.75;
}

.btn:hover {
    opacity: 1;
}

.btn-sm {
    height: 2rem !important;
    line-height: 2rem !important;
    font-size: 0.7rem !important;
}

@media (max-width: 768px) {
    .btn-hide-text>span {
        display: none;
    }
}

.btn-transparent {
    border: 1px solid #CCC !important;
}

.btn-gmail {
    display: inline-block;
    padding: 1rem;
}

/*
 *
 * COLORED
 *
 */

.text-red {
    color: #d53e3e;
}


/*
 *
 * OTHERS
 *
 */

.status-green {
    background-color: #3c8668;
    color: #3c8668;
}

.status-orange {
    background-color: #ff7900;
    color: #ff7900;
}

.status-red {
    background-color: #ef356a;
    color: #ef356a;
}

.status-black {
    background-color: #000;
    color: #000;
}

.background-red {
    background-color: #ef356a !important;
}


.status-gray {
    color: gray;
}

.border-bottom-theme {
    border-bottom: 1px solid #c6c4c0;
}

.border-top-theme {
    border-top: 1px solid #EEE;
}

.fw-500 {
    font-weight: 500 !important;
}

.button-main {
    color: white !important;
    text-decoration: none !important;
    padding: 1rem 3.5rem;
    background: linear-gradient(217.49deg, #5A31DE 0%, #2E16BA 100%);
    border: 0;
    border-radius: 999px;
    font-weight: bold;
    font-size: 1.1rem;
}

.button-main-bordered {
    color: #2E16BA !important;
    text-decoration: none !important;
    padding: 1rem 3.5rem;
    background: white;
    border: 3px solid #2E16BA;
    border-radius: 999px;
    font-weight: bold;
    font-size: 1.1rem;
}

.button-gmail {
    display: inline-block;
    color: #333 !important;
    text-decoration: none !important;
    padding: 1rem 3.5rem;
    background: white;
    border: 3px solid #ccc;
    border-radius: 20px;
    font-weight: bold;
    font-size: 1.1rem;
}

.button-gmail>span {
    line-height: 24px;
}


.text-themed {
    color: #2E16BA !important;
}

.button-secondary {
    color: black !important;
    text-decoration: none !important;
    padding: 1rem 3.5rem;
    background: #FFFFFF;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    border: 0;
    border-radius: 999px;
    font-weight: bold;
    font-size: 1.1rem;
}

.button-secondary.small {
    font-size: 0.8rem;
    padding: 0.5rem 1rem;
}

.button-main.small-padding {
    font-size: 1rem;
    padding: 0.75rem 1.5rem;
}

.error-block {
    background-color: #e9646d;
    color: white;
    border-radius: 10px;
}

.error-block>div>span:first-child {
    display: block;
    font-size: 0.9rem;
}

.error-block>div>span:last-child {
    display: block;
    font-size: 0.8rem;
    opacity: 0.8;
}

.container-new {
    margin-top: 3rem;
}

.search-input {
    font-size: 0.8rem;
    background-color: #f4f4f4 !important;
    border: 0 !important;
    border-radius: 6px;
    padding: 0.5rem;
}

.provider-connection-create-item-new {
    margin: 2px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    cursor: pointer;
}

.progress-bar {
    background-color: #4424cc !important;
}

.dashboard-item {
    background-color: white;
    border-radius: 20px;
    box-shadow: var(--shadow-lg);
}

.dashboard-item-cta {
    width: 100%;
    color: white;
    border-radius: 999px;
    border: none;
    opacity: 0.90;
}

.dashboard-item-cta:hover {
    opacity: 1;
}

.btn-of-list {
    width: 40px;
    height: 40px;
    padding: 5px;
    border: none;
    background: none;
    border-radius: 999px;
    text-align: center;
}

.btn-of-list:hover {
    color: #4021C8;
    background: #dcdce9;
}

.btn-of-list>img {
    fill: orange;
}

.text-overflow-dots {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.product-edit-item {
    background: white;
    border-radius: 10px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.06);
}


@media (max-width: 1200px) {

    .container-new {
        margin-top: 3rem;
        max-width: none;
    }

}

@media (max-width: 768px) {

    .container-new .col-12 {
        padding: 0;
    }

    .white-box {
        border-top: 1px solid #EEE;
        box-shadow: none;
        border-radius: 0;
        padding: 5rem 2rem;
    }

    .auth-alert {
        box-shadow: none;
        border-radius: 0;
    }

}

.scrollbar::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, .16);
    border-radius: 50px
}

.scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, .24);
}

.scrollbar::-webkit-scrollbar {
    height: 8px;
    width: 8px;
}

.scrollbar::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
}

.gist-messenger-bubble-iframe iframe {
    z-index: var(--z-index-bubble)!important;
}

/** 
 * Fix for ApexCharts tooltip text
 */
.apexcharts-tooltip-text-goals-value,
.apexcharts-tooltip-text-y-value,
.apexcharts-tooltip-text-z-value {
    font-weight: 500!important;
}