.svg circle {
    stroke-width: 6px;
    stroke-linecap: round;
    stroke: #7044C8;   
}

.svg {
    transform: rotate(-90deg);
    height: auto;
    pointer-events: none;
    width: 100%;
}

.bgButton {
    background-color: #fff;
    border-radius: 50%;
    box-shadow: var(--shadow-md);
    width: 100%;
    aspect-ratio: 1 / 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.button {
    height: 32px;
    width: 32px;
    padding: 0;
    background-color: transparent;
    border: none;
    display: grid;
    place-items: center;
    margin-top: -8px;
    margin-bottom: -8px;
    flex-shrink: 0;
}

.button > * {
    grid-column: 1 / 1;
    grid-row: 1 / 1;
}

.menu {
    background-color: #fff;
    color: #222222;
    padding: 6px;
    border-radius: 8px;
    font-size: 14px;
    box-shadow: 0 4px 15px -2px rgba(51,51,51,0.3);
    width: 232px;
    padding: 10px 15px;
    margin-top: 4px;
}

@media (min-width: 768px) {
    .button {
        margin: 0;
        width: 48px;
        height: 48px;
    }
}

@media (min-width: 1200px) {
    .menu {
        box-shadow: 0 6px 8px -9px rgba(0,0,0,0.18), 0 4px 14px -4px rgba(51,51,51,0.23);
    }
}

.circle {
    height: 18px;
    width: 18px;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: inset 0 0 0 2px rgba(0,0,0,0.1);
}

.circleCompleted {
    background-color: #7044C8;
    width: 100%;
    height: 100%;
}

.percentage {
    color: #7044C8;
    font-size: 22px;
    font-weight: bold;
    line-height: 1;
}

.colorLabel {
    color: #7044C8;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: bold;
    letter-spacing: 0.55px;
    line-height: 1.5;
}

.progressBar {
    height: 8px;
    width: 100%;
    background-color: #F4F4F4;
    border-radius: 10px;
    margin-bottom: 16px;
}

.progress {
    height: 100%;
    border-radius: 10px;
    background: linear-gradient(90deg, #544FC6 0%, #9E37AC 100%);
}

.taskList {
    padding: 0;
    margin: 0;
    list-style: none;
}

.taskLink {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 0;
    text-decoration: none;
    color: rgba(34, 34, 34, 0.4);
    gap: 5px;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: -0.1px;
    line-height: 1.31;
}

.taskLink:not(.isCompleted):hover {
    color: #793DC4;
}

.taskLink.isCompleted {
    color:#DA3984;
}

.check {
    width: 100%;
    height: auto;
}

.relative {
    position: relative;
}

.arrow {
    opacity: 0;
    position: absolute;
    right: -4px;
    top: 50%;
    transform: translate(50%, -50%);
    transition: all 0.2s ease-in-out;
}

.taskLink:not(.isCompleted):hover .arrow {
    opacity: 1;
    transform: translate(100%, -50%);
}

.close {
    position: absolute;
    right: 4px;
    top: 12px;
}