.textButton {
    color: #222;
    font-weight: 400;
    display: inline-flex;
    align-items: center;
    padding: 0;
    text-decoration: none;
    border: none;
    background: transparent;
    cursor: pointer;
    transition: color 0.2s;
}

.textButton:disabled {
    color: #222;
    opacity: 0.5;
}

.textButton:hover {
    color: #222;
}

.bold {
    font-weight: bold;
}

.primary,
.primary:hover,
.primary:disabled { 
    color: #4021C8;
}

.grey,
.grey:disabled {
    color: #919191;
}

.grey:hover {
    color: #222;
}

.black,
.black:hover {
    color: #222;
}

.small {
    font-size: 0.8rem;
}