:root {
  --checkbox-size: 20px;
}
.base {
  position: relative;
  display: inline-flex;
  align-items: flex-start;
  gap: 8px;
}

.boxIcon {
  box-sizing: border-box;
  width: var(--checkbox-size);
  height: var(--checkbox-size);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  position: absolute;
}

.boxIcon svg {
  width: 100%;
  height: 100%;
  display: block;
}

.input {
  width: var(--checkbox-size);
  height: var(--checkbox-size);
  display: flex;
  color: #1f1f1f;
  border: 1.5px solid #c2c2c2;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  flex-shrink: 0;
  appearance: none;
  box-sizing: border-box;
  background-color: #ffffff;
}

.input:focus:not(:focus-visible) {
  outline: 0;
}

.input:disabled {
  background-color: #eeeeee;
  border-color: #c2c2c2;
}

.input:disabled + .boxIcon {
  color: rgba(0, 0, 0, 0.5);
}

.input:not(:disabled) + .boxIcon {
  cursor: pointer;
}

.fullWidth {
  display: flex;
  width: 100%;
}

.content {
  flex-grow: 1;
}
