:root {
    --radius-pill: 99999px;

    --switch-width: 42px;
    --switch-height: 24px;
    --switch-padding: 2px;

    --switch-background: linear-gradient(180deg, #5A31DE 0%, #2E16BA 100%);
    --switch-background: #D9D9D9;
    --switch-background-checked: linear-gradient(180deg, #5A31DE 0%, #2E16BA 100%);
    --switch-background-disabled: #ECECEC;
    --switch-background-disabled-checked: #B4ABE5;
    --switch-marker-background: #ffffff;
    --switch-transition-duration: .2s;
}

.switch {
    position: relative;
    display: inline-flex;
    flex-shrink: 0;
    align-items: center;
    width: var(--switch-width);
    height: var(--switch-height);
    border-radius: var(--radius-pill);
    padding: var(--switch-padding) 0;
}

.small {
    --switch-width: 30px;
    --switch-height: 18px;
}

/* increase interactive area */
.small .label::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    transform: scale(1.8);
    z-index: 4;
    border-radius: var(--radius-pill);
}

.input, .label {
    position: absolute;
    left: 0;
    top: 0;
}

.input:not(:disabled) {
    cursor: pointer;
}

.input:not(:disabled)+.label {
    cursor: pointer;
}

.input {
    opacity: 0;
    height: 0;
    width: 0;
    pointer-events: none;
}

.input:checked+.label {
    background: var(--switch-background-checked);
}

.input:checked+.label+.marker {
    left: calc(100% - var(--switch-height) + var(--switch-padding));
}

.input:disabled+.label {
    background: var(--switch-background-disabled);
}

.input:checked:disabled+.label {
    background: var(--switch-background-disabled-checked);
}

.input:disabled+.label+.marker {
    box-shadow: none;
}

.label {
    width: 100%;
    height: 100%;
    color: transparent;
    user-select: none;
    background-color: var(--switch-background);
    border-radius: inherit;
    z-index: 1;
}

.marker {
    position: relative;
    background-color: var(--switch-marker-background);
    width: calc(var(--switch-height) - var(--switch-padding) * 2);
    height: calc(var(--switch-height) - var(--switch-padding) * 2);
    border-radius: 50%;
    z-index: 2;
    pointer-events: none;
    left: var(--switch-padding);
    transition: left var(--switch-transition-duration);
    will-change: left;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}