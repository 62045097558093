
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.drawerContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 32px 12px 16px;
    box-sizing: border-box;
}

.secondaryNav {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 0 10px 10px;
}

.secondaryNavItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    font-size: 15px;
    color: rgba(32, 32, 32, .5);
}

.currentColor {
    color: currentColor;
}

.navItem {
    display: flex;
    text-decoration: none;
    font-size: 15px;
    font-weight: 500;
    position: relative;
    gap: 10px;
    align-items: center;
    padding: 6px;
    border-radius: 8px;
    color: rgba(32, 32, 32, .5);
    border: 0;
    background-color: transparent;
    width: 100%;
    text-align: left;
}

.navItem:hover {
    color: #222;
}

.current {
    color: rgba(34, 34, 34, 1);
}

.navItem.current .badge {
    background-color: #FFFFFF;
}

.navItem.current {
    color: #222;
    background-color: rgba(32, 32, 32, .07);
}

.mobileNav ul {
    flex-direction: column;
    display: flex;
    gap: 12px;
    list-style: none;
    margin: 0;
    padding: 0 0 24px;
}

.divider {
    background-color: rgba(32,32,32,.1);
    border-radius: 2px;
    height: 2px;
    margin: 12px 2px;
}

.dividerSmall {
    background-color: rgba(32,32,32,.1);
    height: 1px;
}