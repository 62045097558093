.card {
    padding: 16px 12px;
    color: #222;
    transform: translateZ(0);
}

.header {
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: 13px;
    margin-bottom: 24px;
}

.logo {
    width: 36px;
    height: auto;
    display: block;
}

.nameProvider {
    font-weight: 500;
}

.content {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    margin-bottom: 4px;
    gap: 12px;
}

.flexEnd {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.primaryText {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.2;
}

@media (min-width: 768px) {
    .card {
        padding: 24px 20px;
    }
    .primaryText {
        font-size: 24px;
    }
    .header {
        margin-bottom: 30px;
    }
}

.date {
    display: flex;
    justify-content: flex-end;
}

.isIncome {
    color: #3C8668;
}