.content {
  background-image: linear-gradient(
    180deg,
    #fbf6f0 0%,
    #ffffff 82%,
    #ffffff00 82%,
    #ffffff00 100%
  );
  margin-left: calc(var(--dialog-padding-x) * -1);
  margin-right: calc(var(--dialog-padding-x) * -1);
  padding: 24px var(--dialog-padding-x) 0;
}

.isLoading {
  height: 449px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 56px;
}

@media (max-width: 767px) {
  .content {
    overflow: hidden;
  }
}

.group {
  display: grid;
}

.group > * {
  grid-area: 1/ -1;
}

.paginationText {
  font-size: 16px;
  opacity: 0.5;
  font-weight: 400;
  padding: 0 12px;
}

.dialog {
  --dialog-padding-bottom: 0;
  --dialog-header-margin-bottom: 0;

  outline: none;
}

.dialog button {
  outline: none;
}

.relative {
  position: relative;
}

.paginationButton {
  color: #909090;
  transition: color 0.2s ease-in-out;
}

.paginationButton:hover:not(:disabled) {
  color: #222;
}

.paginationButton:not(:disabled) {
  cursor: pointer;
}
