.toggle {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  outline: 0;
  border-width: 1px;
  border-style: solid;
  background-color: #fff;
  box-sizing: border-box;
  border-color: #d9d9d9;
  color: #222222;
  padding-inline-start: 12px;
  padding-inline-end: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 8px;
  transition-property: border-color, box-shadow;
  transition-duration: 0.2s;
  font-size: 14px;
  box-shadow: 0 0 0 0 transparent;
  height: 37px;
  text-align: left;
  line-height: 1.1;
}

.toggle.open {
  border-color: #4021c8;
  box-shadow: 0 0 8px 0 rgba(64, 33, 200, 0.2);
  color: #4021c8;
}

.list {
  --dropdown-list-padding: 6px;

  background-color: #fff;
  color: #222222;
  padding: var(--dropdown-list-padding);
  border-radius: 8px;
  font-size: 14px;
  box-shadow: 0 6px 14px 0 rgb(0 0 0 / 15%);
}

.divider {
  height: 1px;
  background-color: #ebe8f9;
  margin: 4px calc(var(--dropdown-list-padding) * -1);
}

.item {
  padding: 6px 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  background-color: #fff;
  border: 0;
  outline: 0;
  font-size: 14px;
  transition: background-color 0.2s;
  min-width: 120px;
  border-radius: 5px;
  color: #222222;
  text-align: left;
  width: 100%;
}

.disabled {
  color: #dddddd;
  cursor: not-allowed;
}

.item:hover,
.item:focus-visible {
  background-color: rgba(0, 0, 0, 0.04);
}
