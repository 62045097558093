.arrow {
  position: absolute;
  background-color: inherit;
  width: 8px;
  height: 8px;
  transform: rotate(45deg);
  border-radius: 1px;
}

.tooltip {
  background-color: #222;
  color: #fff;
  padding: 5px;
  border-radius: 4px;
  line-height: 1.3;
  font-size: 13px;
  font-weight: 400;
  pointer-events: none;
  z-index: var(--z-index-tooltip);
}

.innerTooltip {
  position: relative;
  display: inline-block;
  background-color: inherit;
  padding-inline-start: 4px;
  padding-inline-end: 4px;
  text-align: center;
  width: max-content;
  max-width: 310px;
}
