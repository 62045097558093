.badge {
    color: #fff;
    display: inline-block;
    border-radius: 999px;
    padding: 5px 8px;
    font-size: 9px;
    text-transform: uppercase;
    font-weight: 700;
    margin-left: 6px;
    line-height: 1;
    vertical-align: middle;
    letter-spacing: 0.5px;
    background-color: #222;
}

.personal {
    background: linear-gradient(to right,  #f18826 0%, #fd4d8d 100%);
}

.business {
    background: linear-gradient(to right, #407EFF 0%, #1E0989 100%);
}

.advanced {
    background: linear-gradient(to right, #666 0%, #000 100%);
}