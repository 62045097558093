:root {
    --drawer-footer-height: 0px;
}
  
.paperDrawer {
    width: 272px;
    max-width: calc(100% - 32px);
    overflow-x: hidden;
}

.xs {
    width: 272px;
}

.sm {
    width: 321px;
}

.md {
    width: 496px;
}

.lg {
    width: 600px;
}

.footer {
    padding: 20px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.content {
    flex-grow: 1;
    padding: 20px 20px calc(var(--drawer-footer-height) + 12px);
    position: relative;
    display: flex;
    flex-direction: column;
}

@media (min-width: 768px) {
    .content {
        padding: 24px 32px calc(var(--drawer-footer-height) + 20px);
    }
    .footer {
        padding: 24px 32px;
    }
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 0;
    flex-shrink: 0;
    gap: 4px;
}

.title {
    font-size: calc(1.275rem + .3vw);
    margin: 0;
    font-weight: 500;
    line-height: 1.2;
}

@media (min-width: 1200px) {
    .title {
        font-size: 1.5rem;
    }
}

.back {
    flex-shrink: 0;
    margin-right: 8px;
}

.close {
    margin-left: auto;
    flex-shrink: 0;
}