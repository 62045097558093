.handle {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    cursor: pointer;
    background-color: #fff;
    border: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 24px;
    text-align: left;
    color: inherit;
    overflow: hidden;
}

.handle:focus-visible {
    outline: 0;
    box-shadow: 0 0 0 2px #4021C8, 0 0 8px 0 rgba(64, 33, 200, .25);
}

.arrow {
    margin-left: 4px;
    transform: translateX(4px);
    transition: transform 0.2s ease-in-out;
    flex-shrink: 0;
}

.handle:hover .arrow {
    transform: translateX(8px);
}

.loading {
    flex-shrink: 0;
    padding: 0 4px;
}

.busy {
    pointer-events: none;
}

.ellipsis { 
    overflow: hidden; 
    text-overflow: ellipsis; 
    white-space: nowrap; 
}

.minWidth {
    min-width: 0;
}