.spinner {
    animation: rotator 1.4s linear infinite;
}

@keyframes rotator {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(270deg); }
}

.path {
    stroke-dasharray: 187;
    stroke-dashoffset: 0;
    transform-origin: center;
    animation:
            dash 1.4s ease-in-out infinite,
            colors 5.6s ease-in-out infinite;
}

@keyframes colors {
    0% { stroke: #F369AD; }
    25% { stroke: #6A51FF; }
    50% { stroke: #FF8D36; }
    75% { stroke: #D538DF; }
    100% { stroke: #0A34A5; }
}

@keyframes dash {
    0% { stroke-dashoffset: 187; }
    50% {
        stroke-dashoffset: 46.75;
        transform:rotate(135deg);
    }
    100% {
        stroke-dashoffset: 187;
        transform:rotate(450deg);
    }
}
