.statusInfo {
  width: 100%;
  border-radius: 9999px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 8px 12px;
  margin: 24px 0;
  font-weight: 500;
  color: #222;
  background-color: #f5f5f5;
  text-wrap: balance;
}
