
.iconButton {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    padding: 0;
    border: none;
    background-color: transparent;
}

.iconButton:disabled {
    opacity: .35;
}