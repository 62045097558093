.container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-right: 1.5rem ;
    padding-left: 1.5rem;
}

@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 720px;
        padding-left: 0;
        padding-right: 0;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1140px;
    }
}

@media (min-width: 1400px) {
    .container {
        max-width: 1320px;
    }
}