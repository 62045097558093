.chip {
    font-size: 12.5px;
    font-weight: 500;
    letter-spacing: 0;
    padding: 4px 12px;
    border-radius: 99999px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    background-color: rgba(var(--chip-color-rgb), .16);
    color: rgb(var(--chip-color-rgb));
    transition: background-color .2s;
    line-height: 1.25;
    text-align: center;
    align-items: center;
    gap: 6px;
    text-align: left;
    position: relative;
}

.chipIcon {
    font-size: 16px;
    line-height: 1;
}

.chip[role="button"]:hover {
    background-color: rgba(var(--chip-color-rgb), .24);
}

.chip[role="button"] {
    cursor: pointer;
}

.chipDelete {
    background-color: #fff;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,.2);
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(25%, -50%);
    width: 18px;
    height: 18px;
    
    display: none;
}

.chipDelete::after, 
.chipDelete::before {
    content: "";
    display: block;
    width: 10px;
    height: 2px;
    background-color: #7c7c7c;
    position: absolute;
    top: 50%;
    left: 50%;
}

.chipDelete::before {
    transform: translate(-50%, -50%) rotate(-45deg);
}

.chipDelete::after {
    transform: translate(-50%, -50%) rotate(45deg);
}

@media (hover: hover) {
    .chipDelete {
        opacity: 0;
        display: block;
    }

    .chip:hover .chipDelete {
        opacity: 1;
    }
}