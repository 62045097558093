.buttonWorkspace {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  padding: 8px 4px 8px 8px;
  cursor: pointer;
  background-color: #fff;
  border: 0;
  outline: 0;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s ease-in-out;
  color: rgba(33, 33, 33, 0.5);
  text-align: left;
  width: 100%;
}

.buttonWorkspaceLabel {
  color: rgba(33, 33, 33, 0.5);
  font-size: 9px;
  letter-spacing: 0.5px;
  line-height: 12px;
  text-transform: uppercase;
  padding: 2px 0;
}

.buttonWorkspaceName {
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  color: #222;
}

.buttonWorkspaceIcon {
  flex-shrink: 0;
}

.goBack {
  position: relative;
  top: -32px;
  display: inline-block;
}

@media (min-width: 768px) {
  .goBack {
    top: -42px;
  }
}

.message {
  padding: 24px 0 32px;
  text-align: center;
  max-width: 330px;
  margin: 0 auto;
}
