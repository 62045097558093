:root {
    --dialog-padding-x: 20px;
    --dialog-padding-top: 56px;
    --dialog-padding-bottom: 40px;
    --dialog-max-width: 640px;
    --dialog-header-padding-y: 24px;
    --dialog-header-margin-bottom: 12px;
}

.overlay {
    z-index: var(--z-index-modal);
    display: flex;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(2px);
    overflow: auto;
}

.dialog {
    background-color: #fff;
    width: 100%;
    position: relative;
    margin: auto;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.dialog.small {
    --dialog-max-width: 500px;
}
.dialog.large {
    --dialog-max-width: 820px;
}
.dialog.extraLarge {
    --dialog-max-width: 1280px;
}

@media (min-width: 768px) {
    :root {
        --dialog-padding-top: 68px;
        --dialog-padding-bottom: 52px;
        --dialog-padding-x: 40px;
    }

    .dialog {
        border-radius: 25px;
        width: calc(100% - 40px);
        max-width: var(--dialog-max-width);
        flex-grow: 0;
    }
}

.closeButton {
    position: absolute;
    top: 26px;
    right: 24px;
    z-index: 1;
}

.title {
    font-size: 22px;
    font-weight: 600;
    line-height: 1.2;
    padding:
        var(--dialog-header-padding-y)
        calc(var(--dialog-padding-x) + 32px)
        var(--dialog-header-padding-y)
        var(--dialog-padding-x);
    margin-bottom: var(--dialog-header-margin-bottom);
}

.content {
    padding: var(--dialog-padding-top) var(--dialog-padding-x) var(--dialog-padding-bottom);
    flex-grow: 1;
}

.content.hasTitle {
    padding-top: 0;
}