:root {
    --text-field-border-size: 1px;
}

.input {
    -webkit-tap-highlight-color: transparent;
    font: inherit;
    letter-spacing: inherit;
    appearance: none;
    display: block;
    min-width: 0;
    width: 100%;
    outline: 0;
    border-width: var(--text-field-border-size);
    border-style: solid;
    background-color: #fff;
    box-sizing: border-box;
    border-color: #d9d9d9;
    color: #222222;
    padding-inline-start: 12px;
    padding-inline-end: 12px;
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 8px;
    transition-property: border-color, box-shadow;
    transition-duration: 0.2s;
    font-size: 14px;
    box-shadow: 0 0 0 0 transparent;
}

.input:focus {
    border-color: #4021C8;
    box-shadow: 0 0 8px 0 rgba(64,33,200,0.2);
    color: #4021C8;
}

.input:not(textarea) {
    height: 37px;
}

.input:disabled {
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    color: rgba(0,0,0,0.25);
    cursor: not-allowed;
}

.input::placeholder {
    color: rgba(0,0,0,0.25);
}

textarea.input {
    resize: vertical;
    padding-top: 8px;
    padding-bottom: 8px;
    line-height: 1.5;
    min-height: 82px;
}

.inputWrap {
    position: relative;
}

.label {
    font-size: 14px;
    display: block;
    margin-bottom: 4px;
    color: #848280;
    font-weight: 500;
}

.large {
    --text-field-border-size: 2px;
}

.large .input {
    font-size: 18px;
    padding-top: 12px;
    padding-bottom: 12px;
}

.large .input:not(textarea) {
    height: 60px;
}

.startAdornmentSpace {
    padding-inline-start: calc(var(--input-adornment-size) + var(--input-adornment-space) * 2);
}

.endAdornmentSpace {
    padding-inline-end: calc(var(--input-adornment-size) + var(--input-adornment-space) * 2);
}