.gradientButton,
.gradientButton:hover  {
    background: linear-gradient(217.49deg, #5A31DE 0%, #BA16A1 100%);
}

.gradientButton {
    padding-top: .6rem;
    padding-bottom: .6rem;
    padding-left: .75rem;
    height: 48px;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    font-size: 14px;
    font-weight: 600;
    flex-shrink: 0;
}

.content {
    display: flex;
    align-items: center;
    gap: 6px;
}

.narrow {
    padding-left: .7rem;
    padding-right: .7rem;
    padding-top: .45rem;
    padding-bottom: .45rem;
    margin-top: -8px;
    margin-bottom: -8px;
    height: 36px;
}
