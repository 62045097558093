.tab {
    font-weight: 500;
    font-size: 14px;
    line-height: 1;
    padding: 10px 24px;
    color: rgba(32, 32, 32, .5);
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    background-image: none;
    position: relative;
    border-radius: 9999px;
    transition: color .2s ease-in-out;
    border: none;
}

.tab:focus, 
.tab:focus-visible {
    outline: none;
}

.tab:focus-visible,
.tab:hover {
    color: #222;
}

.tab:not(:disabled) {
    cursor: pointer;
}

.tabs {
    display: flex;
    gap: 4px;
    width: max-content;
    background-color: rgba(32, 32, 32, .1);
    border-radius: 9999px;
    padding: 4px;
}

.active {
  color: #222;
}

.tabContent {
    position: relative;
}

.bubble {
    border-radius: 9999px;
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.09);
    position: absolute;
    inset: 0;
}
