:root {
    --radio-size: 26px;
    --radio-color: #4021C8;
}

.input {
    width: var(--radio-size);
    height: var(--radio-size);
    display: block;
    border-radius: 50%;
    position: relative;
    transition: box-shadow 0.2s;
    box-shadow: inset 0 0 0 2px #d3d3d3;
    appearance: none;
    border: none;
    background-color: #ffffff;
}

.input:not(:disabled) {
    cursor: pointer;
}

.input:checked {
    box-shadow: inset 0 0 0 7px var(--radio-color);
}

.input:focus {
    outline: none;
}

.input:disabled {
    background-color: #f5f5f5;
}

.label {
    display: inline-flex;
    align-items: center;
    gap: 12px;
    font-size: 18px;
}

.label:hover .input:not(:disabled):not(:focus-visible):not(:checked) {
    box-shadow: inset 0 0 0 2px #afafaf;
}

.radioGroup {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
}

.columnDireccion {
    flex-direction: column;
}


/* RadioCard */
.radioCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    padding: 20px 16px 12px;
    border-radius: 10px;
    background-color: #FFFFFF;
    transition: box-shadow 0.2s;
    cursor: pointer;
    position: relative;
    box-shadow: inset 0 0 0 0px var(--radio-color), 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    width: 136px;
    margin-top: 4px;
}

.radioCardChecked {
    position: absolute;
    top: 2px;
    right: 2px;
    width: 29px;
    height: 29px;
    border-radius: 50%;
    background-color: var(--radio-color);
    transform: translate(50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
}

.radioCardSelected {
    box-shadow: inset 0 0 0 3px var(--radio-color),
        0 1px 3px 0 rgba(0, 0, 0, 0.12),
        0 2px 4px 0 rgba(0, 0, 0, 0.06);
}

.radioCardInput {
    border: 0px;
    clip: rect(0px, 0px, 0px, 0px);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0px;
    overflow: hidden;
    white-space: nowrap;
    position: absolute;
}

.radioCardLabel {
    font-size: 15px;
    font-weight: bold;
    display: block;
    width: 100%;
    text-align: left;
}

.radioCardImageWrapper {
    width: 100%;
    height: 57px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.radioCardImage {
    height: 100%;
    width: 100%;
    object-fit: contain;
    display: block;
}