:root {
    --profile-menu-width: 184px;
}

.handle {
    border-radius: 9999px;
    padding: 5px 12px 5px 6px;
    display: flex;
    gap: 6px;
    align-items: center;
    border: 0;
    background-color: #fff;
    color: rgb(33, 33, 33, .5);
}

.root {
    position: relative;
    filter: drop-shadow(var(--shadow-md));
    z-index: var(--z-index-dropdown);
}

.handle.expanded {
    border-radius: 23px 23px 0 0;
}

.name {
    display: none;
}

.dropdown {
    background-color: #fff;
    border-radius: 10px 0 10px 10px;
    width: 100%;
    min-width: var(--profile-menu-width);
    overflow: hidden;
}

.menu {
    --menu-padding: 8px;
    padding: calc(var(--menu-padding) / 2) var(--menu-padding) var(--menu-padding);
}

.menuItem {
    padding: 8px 4px 8px 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    background-color: #fff;
    border: 0;
    outline: 0;
    font-size: 14px;
    font-weight: 500;
    transition: all 0.2s ease-in-out;
    color: rgba(33, 33, 33, 0.5);
    text-align: left;
    width: 100%;
    border-radius: 6px;
}

.menuItem:hover, .menuItem:focus-visible {
    background-color: rgba(32, 32, 32, .07);
    color: #222;
}

.divider {
    height: 1px;
    width: calc(100% + var(--menu-padding) * 2);
    background-color: #F4F4F4;
    margin-left: calc(var(--menu-padding) * -1);
    margin-right: calc(var(--menu-padding) * -1);
}

.showInLargeDropdown {
    display: none;
}

@media(min-width: 1168px) {
    .handle {
        width: var(--profile-menu-width);
    }
    .name {
        font-size: 14px;
        flex-grow: 1;
        min-width: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        text-align: left;
    }
    .dropdown {
        border-radius: 0 0 10px 10px;
    }
    
    .showInLargeDropdown {
        display: block;
    }
}
