.disableEvents {
    pointer-events: none;
}

.visibleHidden {
    visibility: hidden;
}

.slice {
    transform: translateZ(0);
}

.cardDraggable {
    border-radius: 20px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 8px 0 rgba(0,0,0,0.08);
    cursor: grab;
    transition: box-shadow .2s ease-in-out;
}

.dragging {
    box-shadow: 0 6px 20px 0 rgba(0,0,0,0.2);
    cursor: grabbing;
}


.footer {
    display: flex;
    gap: 12px;
    border-top: 1px solid rgba(51, 51, 51, .06);
    padding: 16px var(--dialog-padding-x);
    margin-left: calc(var(--dialog-padding-x) * -1);
    margin-right: calc(var(--dialog-padding-x) * -1);
}

.footer > * {
    flex-grow: 1;
}

.description {
    opacity: .5;
    font-size: 16px;
    margin-bottom: 16px;
}

.chip.chip {
    font-size: 20px;
    padding: 16px 24px;
}

.chip.chip > * {
    font-size: inherit;
}

.disableSelection {
    user-select: none;
    -webkit-user-select: none;
}

@media (min-width: 540px) {
    .description {
        font-size: 17px;
    }
    .footer {
        padding-top: 24px;
        padding-bottom: 24px;
    }
}

.disabled {
    display: flex;
    flex-direction: column;
    position: absolute;
    inset: 0;
}

.visibleHidden {
    flex-shrink: 0;
}

.cardDisabled {
    overflow: hidden;
    flex-shrink: 1;
    border-radius: 20px;
}