.page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.container {
    width: 100%;
    max-width: 500px;
    padding: 20px 24px 48px;
    text-align: center;
}