:root {
    --input-adornment-space: 8px;
    --input-adornment-size: 24px;
}

.base {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--input-adornment-size);
    overflow: hidden;
    inset-block: 0;
    color: rgba(34,34,34,0.5);
    font-size: 14px;
    font-weight: 500;
}
.end {
    inset-inline-end: calc(var(--text-field-border-size) + var(--input-adornment-space));
}
.start {
    inset-inline-start: calc(var(--text-field-border-size) + var(--input-adornment-space));
}
.pointerEventNone {
    pointer-events: none;
}