.title {
    font-family: var(--f-family-secondary);
    font-size: 38px;
    color: #222;
    line-height: 1.1;
    margin-bottom: 8px;
    letter-spacing: -0.01em;
    line-height: 1.2;
    font-weight: bold;
}

@media (min-width: 768px) {
    .title {
        font-size: 42px;
    }
}

.fontWeight {
    font-weight: 500;
}

.description {
    font-size: 14px;
    color: rgba(32,32,32, .5);
    margin-bottom: 24px;
}

.grid {
    gap: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    justify-content: center;
    width: 100%;
}

@media (min-width: 980px) {
    .grid {
        gap: 34px;
    }

    .columns2 {
        max-width: 820px;
        margin: 0 auto;
    }
}

.offerInfo {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(32,32,32, .5);
    margin: 0px;
    font-size: 0.75rem;
    letter-spacing: -0.02em;
    margin-bottom: 18px;
    font-weight: 600;
}

.label {
    text-transform: uppercase;
    font-size: 0.75rem;
    font-weight: 500;
    letter-spacing: 0.075em;
    text-transform: uppercase;
    color: rgba(32,32,32, .5);
}

.textPrimary {
    color: #4021c8;
}


/* Plan Table */
.planCard {
    border-radius: 20px;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 6px 14px 0px;
    display: flex;
    flex-direction: column;
    color: #222;
}

.planCardHeader {
    min-height: 203px;
    position: relative;
}

.planCardTitle {
    letter-spacing: -1px;
    line-height: 1;
    font-weight: bold;
    padding-top: 12px;
    display: inline-block;
    color: #222;
    font-size: 32px;
}

.gradientTextBlack {
    background: linear-gradient(to right, #000 0%, #666666 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
}

.gradientTextGray {
    background: linear-gradient(to right, #AFAFAF 0%, #666666 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
}

.gradientTextPrimary {
    background: linear-gradient(to right, #BC2AB4 0%, #5370F5 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
}

.gradientTextSecondary {
    background: linear-gradient(to right, #F18826 0%, #FD4D8D 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
}

.gradientTextTertiary {
    background: linear-gradient(90deg, #29D377 0%, #5068E8 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
}

.priceWhole {
    font-weight: 700;
    font-size: 42px;
    letter-spacing: -1px;
    line-height: 1;
}

.planCardContent {
    padding: 24px 32px;
}

.planCardHeader .planCardContent {
    padding-bottom: 32px;
}

.line {
    height: 1px;
    background-color: #222;
    opacity: 0.1;
}

.list {
    padding: 2px 0 12px;
    margin: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.planCardAction {
    padding: 8px 32px 32px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.priceSubtitle {
    color: #777777;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.3;
}

.priceDescription {
    margin: 0px;
    font-size: 0.75rem;
    letter-spacing: -0.02em;
    color: rgb(119, 119, 119);
    position: absolute;
}

.current {
    text-transform: uppercase;
    color: rgb(119, 119, 119, .5);
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 0.05em;
    position: absolute;
    top: 38px;
    right: 25px;
}

.section {
    margin-bottom: 60px;
}

.rotate {
    transform: rotate(180deg);
}

.free {
    color: rgb(119, 119, 119);
    font-weight: bold;
    font-size: 16px;
    line-height: 48px;
}

@media (min-width: 768px) {
    .planTab.planTab  {
        font-size: 16px;
        padding: 4px 8px;
    }
}

.limitValueContainer {
    width: 16px;
    position: relative;
}

.featureIcon {
    display: block;
    width: 28px;
    height: auto;
}

.highlight {
    color: #333333;
    font-weight: 700;
    text-decoration-line: underline;
    text-decoration-color: #FFCB8C;
    text-underline-offset: 3px;
    text-decoration-thickness: 2px;
}

.itemTitle {
    font-weight: 700;
    font-size: 14px;
    line-height: 1.2;
}

.itemDescription {
    font-size: 12px;
    color: #777777;
}

@media (min-width: 1200px) {
    .selfStart {
        align-self: flex-start;
    }
}

.prefixPrice {
    font-size: 15px;
    padding-top: 4px;
    color: #333;
}

.caption {
    color: #777777;
    line-height: 1.5;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 8px;
}

.save {
    position: absolute;
    top: 13px;
    right: 15px;
    background-color: #E7F6E9;
    color: #4BAF3E;
    padding: 4px 8px;
    font-size: 11px;
    line-height: 1.2;
    font-weight: 700;
    border-radius: 5px;
}

.textLarge {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.3;
}

.relative {
    position: relative;
}

.tabsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 32px;
}

.offer {
    position: absolute;
    left: calc(100% + 16px);
    top: 2px;
    display: none;
    width: 56px;
    line-height: 1.15;
    font-weight: bold;
    font-size: 12px;
    opacity:0.35;
    transition: opacity 0.3s;
    filter: grayscale(1);
    color: #4021c8;
}

@media (min-width: 540px) {
    .offer {
        display: block;
    }
}

.actived {
    filter: none;
    opacity: 1;
}


.offerArrow {
    position: absolute;
    top: calc(100% + 4px);
    left: -14px;
    width: 26px;
    height: auto;
    display: block;
}