.dialog {
    background: #fff;
    width: 100%;
    min-height: 100vh;
    z-index: var(--z-index-modal);
    display: grid;
    grid-template-rows: 1fr auto;
    --fullscreen-dialog-padding-x: 20px;
}

.dialogContent {
    padding: 40px var(--fullscreen-dialog-padding-x) 120px;
}

.dialogActions {
    box-shadow: 0 -4px 12px 0 rgba(0, 0, 0, 0.15);
    padding: 1rem var(--fullscreen-dialog-padding-x);
    display: flex;
    justify-content: flex-end;
    background-color: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: var(--z-index-sticky);
}

.dialogContainer {
    max-width: 600px;
    margin: 0 auto;
    width: 100%;
}

.containerExtraLarge {
    max-width: 1120px;
}

.containerLarge {
    max-width: 940px;
}

.containerSmall {
    max-width: 480px;
}

.backButton {
    border: 0;
    padding: 2px 0;
    background: transparent;
    color: #222;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    gap: 16px;
    text-align: left;
    opacity: .5;
}

.backLabel {
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: 1px;
    font-weight: 600;
    line-height: 1;
    padding-top: 4px;
}

.backButton svg {
    width: 32px;
    height: auto;
    display: block;
}

.backInfo {
    font-size: 16px;
    line-height: 1.5;
    color: #222;
}


.dialogHeader {
    display: flex;
    gap: 12px;
    align-items: center;
    margin-bottom: 16px;
    position: relative;
    z-index: 1;
}

.dialogClose {
    margin-left: auto;
}

.dialogCloseButton {
    border: 0;
    padding: 4px 0;
    background: transparent;
    color: #222;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    opacity: .5;
}

.dialogCloseButton svg {
    width: 24px;
    height: 24px;
}

.title {
    font-size: 24px;
    font-weight: 700;
    color: #222;
    margin: 2px 0;
}

@media (min-width: 768px) {
    .dialogHeader {
        position: absolute;
        top: 20px;
        left: var(--fullscreen-dialog-padding-x);
        right: var(--fullscreen-dialog-padding-x);
    }

    .dialogContent {
        padding-top: 96px;
    }

    .backButton {
        gap: 24px;
    }

    .backButton svg {
        width: 46px;
    }

    .dialogCloseButton svg {
        width: 32px;
        height: 32px;
    }

    .title {
        font-size: 32px;
    }
}

@media (min-width: 1200px) {
    .dialogHeader {
        top: 40px;
        left: 40px;
        right: 40px;
    }
}