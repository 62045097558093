.truncate {
    flex: 1;
    min-width: 0;
    position: relative;
}

.truncate span {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

.actived {
    background-color: #f5f5f5;
}

.search {
    padding: 4px 4px 8px;
}

.searchInput input {
    box-shadow: none!important;
}

.options {
    display: grid;
    max-height: 220px;
    overflow-y: auto;
}