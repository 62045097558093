
.editable {
    border: 1px solid rgba(0, 0, 0, 0);
    border-radius: 2px;
    margin: 0 -2px;
    transition: border-color 0.2s ease;
    outline-offset: 3px;
}

.editable:hover {
    outline: 1px solid #E0E0E0;
}

.editable:focus-within {
    outline: 1px solid #b2b2b2;
}

.hasError,
.hasError:hover {
    outline: 1px solid #d32f2f;
}

.input {
    font-size:16px;
    font-weight: 600;
    font-family: inherit;
    line-height: 1.2;
}

.large {
    font-size: 20px;
}

.input:focus {
    border: 0;
    outline: 0;
}

.editButton {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 4px 2px;
    vertical-align: middle;
}

.errorMessage {
    color: #d32f2f;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    margin-top: 3px;
    margin-right: 14px;
    margin-bottom: 0;
    margin-left: 14px;
}


.editable:focus-within .editButton {
    visibility: hidden;
}