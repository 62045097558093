.wrapper {
    min-height: 100vh;
    display: grid;
    place-items: center;
    padding: 24px 0;
}

.container {
    width: 100%;
    padding-right: 16px;
    padding-left: 16px;
    margin-right: auto;
    margin-left: auto;
    max-width: 400px;
}

.content {
    max-width: 290px;
    width: 100%;
    margin: 0 auto 86px;
}

.title {
    font-family: "Pangea", sans-serif;
    font-size: 30px;
    font-weight: 700;
    line-height: 1.26; 
    color: #222;
}

.description  {
    color: rgba(32, 32, 32, .5);
    font-size: 15px;
    line-height: 1.33;
}

.header {
    margin-bottom: 32px;
    text-align: center;
}

.imageContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
}

.image {
    height: 64px;
    width: auto;
    display: block;
}