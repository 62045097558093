.button {
    color: #222;
    text-decoration: none !important;
    padding: 0.75rem;
    background-color: #FFFFFF;
    border: 0;
    font-weight: bold;
    font-size: 18px;
    gap: 8px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 999px;
    transition: all .15s ease-in-out;
    white-space: nowrap;
}

.green {
    color: #469B4A;
    background-color: rgba(70, 155, 74, .2);
}

.green:hover {
    background-color: rgba(70, 155, 74, .1);
}

.green.actived {
    color: #fff;
    background-color: #469B4A;
}

.red {
    color: #D15554;
    background-color: rgba(209, 85, 84, .2);
}

.red:hover {
    background-color: rgba(209, 85, 84, .1);
}

.red.actived {
    color: #fff;
    background-color: #D15554;
}

.grey {
    flex-direction: row-reverse;
    color: rgb(145, 145, 145, .5);
    background-color: #fff;
    box-shadow: inset 0 0 0 3px rgba(151, 151, 151, .21);
}

.grey:hover {
    background-color: rgba(151, 151, 151, .1);
}

.button:not(:disabled) {
    cursor: pointer;
}

.button:disabled {
    opacity: 0.5;
}

@media(min-width: 768px) {
    .button {
        padding: 0.75rem 1.5rem;
        gap: 16px;
    }
}