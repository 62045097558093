.group {
    display: flex;
    column-gap: 0.5rem;
}

.selectContainerNew {
    min-width: 100px;
    flex-shrink: 0;
}

.searchBox {
    border-radius: 8px;
    border: 1px solid #d9d9d9;
    flex-grow: 1;
    -webkit-tap-highlight-color: transparent;
    font: inherit;
    letter-spacing: inherit;
    appearance: none;
    display: block;
    min-width: 0;
    width: 100%;
    outline: 0;
    background-color: #fff;
    box-sizing: border-box;
    color: #222222;
    padding-inline-start: 12px;
    padding-inline-end: 12px;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 14px;
    height: 100%;
}

.searchBox:focus {
    outline: 0;
    color: #4021C8;
}

.searchBox[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
}

.footer {
    width: 100%;
    left: 0;
    height: 60px;
    position: absolute;
    bottom: 0;
    background: #fff;
    padding: 16px 22px;
    border-radius: 0 0 25px 25px;
    box-shadow: 0 -4px 4px 0 rgba(0,0,0,0.15);
}

.grid {
    display: grid;
    grid-template-rows: auto 1fr auto;
    height: calc(100vh - 115px);
}

@media (min-width: 768px) {
    .footer {
        padding: 16px 40px;
    }
    .grid {
        height: 561px;
    }
}

.results {
    overflow: auto;
    height: 100%;
}

.connector {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    background-color: #fff;
}

.connectorButton {
    border-radius: inherit;
    cursor: pointer;
    background-color: #fff;
    border: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 28px 24px;
    text-align: left;
    color: inherit;
    position: relative;
    color: #222222;
    font-size: 12px;
    line-height: 1.2;
    gap: 16px;
}

@media (min-width: 768px) {
    .supportForm {
        padding: 24px;
    }
}

.connectorButton:focus-visible {
    outline: 0;
    box-shadow: 0 0 0 2px #4021C8, 0 0 8px 0 rgba(64, 33, 200, .25);
}

.arrow {
    flex-shrink: 0;
    transform: translateX(4px);
    transition: transform 0.2s ease-in-out;
}

.connectorButton:hover .arrow {
    transform: translateX(8px);
}

.connectorImage {
    width: 40px;
    height: auto;
    display: block;
}

.providerLogo {
    width: 96px;
    height: auto;
    display: block;
    margin-bottom: 8px;
}

.connectorTitle {
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
    margin-bottom: 4px;
}

.connectorBadge {
    text-transform: uppercase;
    font-size: 10px;
    background-color: rgba(64, 33, 200, 0.2);
    color: #4A2CCC;
    border-radius: 6px;
    line-height: 1;
    padding: 6px 8px;
    font-weight: bold;
    letter-spacing: .5px;
}

.connectorBadge.primaryBadge {
    background-color: #4021C8;
    color: #fff;
}

.badges {
    position: absolute;
    top: 4px;
    right: 4px;
    display: flex;
    gap: 4px;
}

.connectorInfoItem {
    display: flex;
    align-items: center;
    gap: 8px;
}

.connectorImageContainer {
   margin-bottom: auto;
}