.bt-button {
  color: black;
  text-decoration: none !important;
  padding: 0.75rem 1.5rem;
  background: #FFFFFF;
  border: 0;
  border-radius: 4px;
  font-weight: bold;
  font-size: 1rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.bt-button--rounded-pill {
  border-radius: 999px;
}

.bt-button:not(:disabled) {
  cursor: pointer;
}

.bt-button:disabled {
  opacity: 0.5;
}

.bt-button--primary,
.bt-button--primary:hover {
  background: linear-gradient(217.49deg, #5A31DE 0%, #2E16BA 100%);
  color: #FFFFFF;
}

.bt-button--secondary {
  background: #FFFFFF;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.06);
}

.bt-button--narrow {
  font-size: 0.8rem;
  padding: 0.375rem .75rem;
}

.bt-button--small {
  font-size: 0.8rem;
  padding: 0.5rem 1rem;
}

.bt-button--medium {
  font-size: 1rem;
  padding: 0.75rem 1.5rem;
}

.bt-button--large {
  font-size: 1rem;
  padding: 1rem 2.5rem;
}

.bt-button--busy {
  opacity: 0.5;
  pointer-events: none;
}

.bt-button--busy .bt-button__content {
  visibility: hidden;
}

.bt-button__content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bt-button__icon {
  margin-right: .5rem;
}

.bt-button--busy .bt-button__icon {
  visibility: hidden;
}

.bt-button__loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media(min-width: 768px) {
  .bt-button--large {
    font-size: 1.1rem;
    padding: 1rem 3.5rem;
  }
}

.bt-button--ghost {
  color: #4021C8;
  background-color: rgba(64, 33, 200, 0.1);
  box-shadow: none;
}

.bt-button--contained {}

.bt-button--outlined{
  background: #FFFFFF;
  box-shadow: inset 0 0 0 3px #4021C8;
  color: #4021C8;
}