.item.item {
    font-family: var(--f-family-primary);
    font-size: 14px;
}

.groupTitle.groupTitle {
    font-family: var(--f-family-primary);
    font-size: 11px;
    letter-spacing: 1px;
    font-weight: 600;
    color: rgba(32, 32, 32, .5);
    text-transform: uppercase;
    line-height: 32px;
}

.row {
    display: grid;
    grid-template-columns: 24px 1fr;
    gap: 8px;
    align-items: center;
}

.cellIcon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo {
    width: 21px;
    height: 21px;
    display: block;
}

.inputValue {
    display: none;
}

.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 0;
}

:global(.MuiSelect-select) .inputValue {
    display: flex;
    align-items: center;
    gap: 8px;
}

:global(.MuiSelect-select) .row {
    display: none;
}