:root {
    --app-sidebar-full-width: 256px;
    --app-sidebar-narrow-width: 60px;
    --app-sidebar-width: var(--app-sidebar-narrow-width);
}

.pinned {
    --app-sidebar-width: var(--app-sidebar-full-width);
}

.headerMobile {
    display: block;
    background-color: #FFFFFF;
    box-shadow: 0 1px 5px 0 rgba(0,0,0,.15);
    padding: 16px 0;
}

.header, .nav {
    display: none;
}

.header {
    margin-bottom: 24px;
}

.content {
    padding: 18px 0 24px;
}

@media (min-width: 768px) {
    .content {
        padding-left: 16px;
        padding-right: 16px;
    }

    .app {
        min-height: 100vh;
        padding-left: var(--app-sidebar-width);
    }

    .headerMobile {
        display: none;
    }
    .header, .nav {
        display: block;
    }
    
    .content {
        padding: 18px 24px 24px;
    }
}

.nav {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    background-color: #ffffff;
    z-index: 2;
    box-shadow: 0 3px 5px 0 rgba(0,0,0,0.15);
    width: var(--app-sidebar-width);
    transition: width 0.2s ease-in-out;
    will-change: width;
    z-index: var(--z-index-fixed);
}

.navContent {
    width: var(--app-sidebar-full-width);
    padding: 0 12px;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.app.expanded .nav {
    width: var(--app-sidebar-full-width);
    overflow-y: auto;
}

.pinButton {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
}

.navHeader:hover .pinButton {
    opacity: 1;
}

.navList {
    padding: 0 0 16px;
    margin: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 12px;
    flex-grow: 1;
    height: 100%;
}

.order1 {
    order: 1;
}

.navItem {
    display: flex;
    gap: 12px;
    align-items: center;
    padding: 6px;
    border-radius: 8px;
    color: rgba(32, 32, 32, .5);
    transition: all 0.2s ease-in-out;
    text-decoration: none;
}

.navItemContent {
    display: flex;
    align-items: center;
    width: 100%;
}

.navItemText {
    font-size: 15px;
    font-weight: 500;
    white-space: nowrap;
    flex-grow: 1;
}

.navItem.current {
    color: #222;
}

.nav:not(:hover) .navItem.current {
    background-color: rgba(32, 32, 32, .07);
}

.navItem:hover {
    color: #222;
    background-color: rgba(32, 32, 32, .07);
}

.nav:not(:hover) .navItem.current .badge,
.navItem:hover .badge {
    background-color: #ffffff;
}

.navHeader {
    min-height: 95px;
    padding: 24px 4px 0;
}

.divider {
    height: 2px;
    border-radius: 2px;
    background-color: rgba(32, 32, 32, .1);
    margin: 12px 2px;
}

.navHeaderTitle {
    display: flex;
    justify-content: space-between;
}

.badge.badge {
    transition: background-color 0.15s ease-in-out;
}

.spaceBottom {
    margin-bottom: 24px;
}

.categorizeButton {
    border-radius: 9999px;
    padding: 12px 14px;
    background-color: #FFFFFF;
    border: none;
    color: #222;
    font-size: 13px;
    font-weight: bold;
    line-height: 1.2;
    text-align: left;
    box-shadow: 0 0 0 1px rgba(34,34,34,0.05), 0 2px 4px 0 rgba(0,0,0,0.09);
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;
    position: relative;
    min-width: 54px;
}

.categorizeButton:focus {
    outline: none;
}

.noWrap {
    white-space: nowrap;
}

.categorizeContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
}

.categorizeIcon {
    width: 26px;
    flex-shrink: 0;
    position: relative;
}

.categorizeIcon img {
    width: 100%;
    height: auto;
    display: block;
}

.dot {
    width: 9px;
    aspect-ratio: 1/1;
    pointer-events: none;
    border-radius: 50%;
    background-color:#FF2600;
    position: absolute;
    right: 4px;
    top: 3px;
}

.categorizeIcon .dot {
    top: -8px;
    right: -6px;
}

.shadow {
    box-shadow: 0 5px 7px 0 rgba(0,0,0,0.09);
}

.joinAvatar {
    position: absolute;
    z-index: 1;
    background-color: #FFFFFF;
    border-radius: 999px;
    box-shadow: 0 2px 3px 0 rgba(0,0,0,0.14);
}

.invitationModal {
    display: flex;
    align-items: center;
    height: 100%;
}