.iconButton, .textButton {
    background-color: #fff;
    color: #222;
    border: 0;
    outline: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s;
}

.iconButton {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    margin-left: -8px;
    color: rgba(32, 32, 32, .7)
}

.textButton {
    padding: 8px 12px;
    gap: 12px;
    font-size: 16px;
    font-weight: 500;
    border-radius: 5px;
}

.textButton:hover, .iconButton:hover {
    background-color: #ebe8f9;
    color: #4022c8;
}

.uppercaseTitle {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    color: rgba(32, 32, 32, .7);
    letter-spacing: .05em;
    padding: 12px 0;
}

.row {
    display: grid;
    gap: 8px;
    grid-template-columns: 2fr 1fr 1fr 24px 24px;
    align-items: center;
    border-top: 1px solid #ECECEC;
    padding: 10px 0;
}

.editing {
    padding: 16px 0;
    align-items: start;
}

.emptyState {
    padding: 16px 8px 24px;
    text-align: center;
    font-size: 12px;
    color: rgba(32, 32, 32, .5);
    letter-spacing: .05em;
}

.name {
    color: #222;
    font-weight: bold;
}

.type, .currency, .name {
    padding: 0 8px;
    line-height: 1.3;
}

.type, .currency {
    color: rgba(32, 32, 32, .5);
}

.currency {
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.errorMessage {
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.2;
    letter-spacing: 0.03333em;
    text-align: left;
    margin:0 14px;
    color: #d32f2f;
    grid-column: 1/-1;
}

.iconCell {
    padding-top: 0px;
}

.editing .iconCell {
    padding-top: 8px;
}