.alert {
    background: #FFFFFF;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    padding: 12px 8px;
    position: relative;
    display: flex;
    gap: 14px;
}

.icon {
    flex-shrink: 0;
    border-radius: 9999px;
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    background-color: rgba(64, 33, 200, .2);
    position: relative;
}

.icon img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
}

.warning .icon {
    background: rgba(255, 138, 0, .2);
}

.error .icon {
    background-color: #FFFFFF;
    position: relative;
}
.error .icon img {
    position: relative;
    z-index: 1;
}

.error .icon::before,
.error .icon::after {
  content: "";
  position: absolute;
  top: 25%;
  left: 25%;
  height: 50%;
  width: 50%;
  border-radius:99999px;
  animation: ripple 1.6s infinite .8s cubic-bezier(.64,.42,.54,1);
}

.error .icon::after {
   animation-delay: 1.6s;
}

@keyframes ripple {
    0% {
      box-shadow:inset 0 0 0 16px rgba(255, 78, 79, .2); 
    }
      100% {
      transform: scale(2);
      box-shadow:inset 0 0 0 0 rgba(255, 78, 79, .2); 
    }
}

.provider-connection .icon {
    padding: 2px;
    background-color: #FFFFFF
}

.provider-connection img {
    border-radius: 9999px;
}

.iconStatus {
    width: 16px;
    height: 16px;
    border-radius: 100%;
    border: 2px solid #fff;
    position: absolute;
    top: -4px;
    right: 0px;
}

.iconStatusError {
    background-color: #ef356a;
}

.iconStatusInfo {
    background-color: #3f51b5;
}

.error .title {
    color: #FF4E4F;
}

.affiliate .title {
    background: linear-gradient(to right, #8413C6 0%, #FF648D 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
}

.affiliate .icon {
    background-color: rgba(214, 173, 255, .2);
}

.close {
    position: absolute;
    right: -4px;
    top: -6px;
    width: 20px;
    height: 20px;
    background-color: #FFFFFF;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.15);
    border-radius: 9999px;
}

.content {
    flex-grow: 1;
    color: #222;
    line-height: 1.3;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    column-gap: 24px;
    row-gap: 12px;
}

.title {
    font-size: 16px;
    font-weight: 600;
}

.description {
    font-size: 13px;
    font-weight: 500;
    color: rgba(32,32,32, .5)
}

.actions {
    flex-shrink: 0;
    display: flex;
    gap: 8px;
    flex-direction: column;
    width: 100%;
}

.ctaContent {
    display: flex;
    gap: 8px;
    align-items: center;
    padding-left: 4px;
}

.secondaryCta {}

@media (min-width: 768px) {
    .actions {
        width: auto;
        flex-direction: row;
    }

    .alert {
        border-radius: 28px;
    }

    .content:not(:has(.secondaryCta)) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .close {
        right: 0;
    }
}

@media (min-width: 1024px) {
    .content:has(.secondaryCta) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
    .alert {
        padding: 6px 12px 6px 6px;
    }
}

@media (hover: hover) {
    .close {
        opacity: 0;
        transition: opacity .2s;
    }

    .alert:hover .close {
        opacity: 1;
    }
}