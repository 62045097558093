
.options {
    background-color: #fff;
    box-shadow: var(--shadow-lg);
    display: grid;
    max-height: 240px;
    overflow-y: auto;
    border-radius: 8px;
    border: 1px solid #DDDDDD;
    outline: none;
}

.options:empty {
    display: none;
}

.options:empty + * {
    border-top: 0;
}

.name {
    font-weight: 600;
}

.description {
    font-size: 12px;
    opacity: .5;
}

.option {
    font-size: 14px;
    padding: 8px 12px;
    display: flex;
    flex-direction: column;
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: #222222;
    line-height: 1.4;
    font-weight: 400;
    outline: none;
    position: relative;
}

.option::before {
    content: "Enter";
    display: block;
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    font-size: 9px;
    text-transform: uppercase;
    color: #222222;
    padding: 4px 10px;
    line-height: 1;
    border-radius: 999px;
    background-color: rgba(255, 255, 255, .1);
    color: #fff;
    font-weight: bold;
    letter-spacing: 1px;
    opacity: 0;
    pointer-events: none;
}

.textButton {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 6px;
    font-weight: 500;
    width: 100%;
    border: none;
    background-color: transparent;
    color: #222222;
    padding: 0;
    font-size: 14px;
}

.iconButton {
    width: 24px;
    height: 24px;
}

.optionContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media (hover: hover) {
    .optionContainer .iconButton {
        opacity: 0;
        transition: opacity 0.15s;
    }
    .optionContainer:hover .iconButton {
        opacity: 1;
    }
}

.wrapper {
    position: relative;
}

.loading {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.addNew {
    box-shadow: inset 0 -1px #DDDDDD;
    padding-bottom: 16px;
    padding-top: 16px;
}

.actived {
    background-color: #4021C8;
    color: #fff;
}

.actived::before {
    opacity: 1;
}
