.isDeleted {
    filter: grayscale(1);
    opacity: 0.5;
}

.productRow {
    border-bottom: 1px solid #E6E6E6;
    padding: 14px 0;
}

.productRow:first-child {
    border-top: 1px solid #E6E6E6;
}

.divider {
    border-bottom: 1px solid #E6E6E6;
    margin: 0 0 14px 0;
    padding: 14px 0 0;
}

.textButton {
    display: inline-block;
    font-weight: 500;
    border: none;
    background-color: transparent;
    color: rgba(33, 37, 41, 0.75);
    font-size: 14px;
    padding: 8px 12px;
}