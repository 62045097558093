.card {
    border-radius: 10px;
    background-color: #FFFFFF;
    box-shadow: var(--shadow-lg);
    padding: 12px 16px;
    position: relative;
}

.layout {
    display: flex;
    align-items: center;
    gap: 12px;
}

.content {
    flex-grow: 1;
    color: #222222;
    max-width: 316px;
}

.icon,
.action {
    flex-shrink: 0;
}

.icon {
    width: 36px;
    text-align: center;
}

.icon img {
    width: 100%;
    height: auto;
    display: block;
}

.action {
    text-align: right;
    width: 88px;
}

.spinner.spinner {
    margin: 0 12px 0 auto;
    display: block;
    opacity: 1;
}

.actionText {
    opacity: 0.5;
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 0.58px;
    text-transform: uppercase;
    line-height: 1.2;
    padding-top: 2px;
}

.title {
    font-size: 16px;
    font-weight: 500;
}

.close {
    position: absolute;
    top: 8px;
    right: 8px;
}

.group {
    display: flex;
    border-radius: 8px;
    border: 1px solid #d9d9d9;
    transition-property: border-color, box-shadow;
    transition-duration: 0.2s;
    box-shadow: 0 0 0 0 transparent;
}

.groupInput {
    flex-grow: 1;
}

.groupInput.groupInput input {
    border: 0;
    border-radius: 8px 0 0 8px;
    height: 35px;
}

.groupInput.groupInput input:focus {
    box-shadow: none;
}

.groupButton {
    flex-shrink: 0;
    border-radius: 0 8px 8px 0;
}

.group:focus-within {
    border-color: #4021C8;
    box-shadow: 0 0 8px 0 rgba(64,33,200,0.2);
}

.group:focus-within .groupButton {
    border-radius: 0 7px 7px 0;
}

